<template>
  <div class="container">
    <div class="title_flex">
      <div class="line"></div>
      <div class="text">入驻专家库</div>
      <div class="line"></div>
    </div>
    <div class="one_box">
      <div class="title">入驻专家库后，可享受</div>
      <div class="two_box">
        <div class="one">
          <div class="img_box">
            <img src="../../../assets/img/home/one_money.png" />
          </div>
          <div class="title_text">获取报酬</div>
          <div class="content">
            依法依规获取相关文件、资料和数据，独立发表工作意见，并获取劳动报酬。
          </div>
        </div>

        <div class="one">
          <div class="img_box">
            <img src="../../../assets/img/home/two_money.png" />
          </div>
          <div class="title_text">在线接单</div>
          <div class="content">
            产学研合作需求并在线接单，将知识经验能力变现，助力企业创新。
          </div>
        </div>

        <div class="one">
          <div class="img_box">
            <img src="../../../assets/img/home/three_money.png" />
          </div>
          <div class="title_text">交流活动</div>
          <div class="content">
            通过成果路演、金博奖、企业走访等活动，实现信息的高效流转，提升信息资源的传播力和价值。
          </div>
        </div>
      </div>
    </div>
    <div class="two_boxs">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <div class="four_flex">
          <div class="one">
            <div class="top_box">
              <div class="img_box">
                <img src="../../../assets/img/home/ji_ben.png" />
              </div>
              <div class="biao_ti">基本信息</div>
            </div>
            <div class="form_box">
              <el-form-item label="姓名" prop="name">
                <el-input
                  v-model="ruleForm.name"
                  placeholder="请输入姓名"
                ></el-input>
              </el-form-item>
              <el-form-item label="性别" prop="gender">
                <el-radio-group v-model="ruleForm.gender">
                  <el-radio label="1">男</el-radio>
                  <el-radio label="2">女</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="籍贯" prop="province">
                <div class="diqv_box">
                  <v-distpicker @selected="onSelected" province="四川省" city="成都市" area="锦江区"></v-distpicker>
                </div>
              </el-form-item>
              <el-form-item label="出生日期" required>
                <el-form-item prop="birthday">
                  <el-date-picker
                    type="date"
                    placeholder="选择日期"
                    v-model="ruleForm.birthday"
                    style="width: 100%"
                  ></el-date-picker>
                </el-form-item>
              </el-form-item>
              <el-form-item label="联系电话" prop="phone">
                <el-input
                  v-model="ruleForm.phone"
                  placeholder="请输入联系电话"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="one">
            <div class="top_box">
              <div class="img_box">
                <img src="../../../assets/img/home/xue_li.png" />
              </div>
              <div class="biao_ti">学历学位</div>
            </div>

            <div class="form_box">
              <el-form-item label="最高学历" prop="education">
                <el-select
                  v-model="ruleForm.education"
                  placeholder="请选择最高学历"
                >
                  <el-option
                    v-for="(item,index) in xueli"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="毕业院校" prop="school">
                <el-input
                  v-model="ruleForm.school"
                  placeholder="请输入毕业院校"
                ></el-input>
              </el-form-item>

              <el-form-item label="研究领域" prop="research">
                <el-select
                  v-model="ruleForm.research"
                  placeholder="请选择研究领域"
                >
                  <el-option
                    v-for="(item,index) in lingyu"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                class="civil"
                label="是否为政府评审专家成员"
                prop="is_civil"
              >
                <el-radio-group v-model="ruleForm.is_civil">
                  <el-radio label="1">是</el-radio>
                  <el-radio label="2">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="职称" prop="job_level">
                <el-input
                  v-model="ruleForm.job_level"
                  placeholder="请输入所属单位类型"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="one">
            <div class="top_box">
              <div class="img_box">
                <img src="../../../assets/img/home/gong_zuo.png" />
              </div>
              <div class="biao_ti">工作单位</div>
            </div>
            <div class="form_box">
              <el-form-item label="工作单位（全称）" prop="company_name">
                <el-input
                  v-model="ruleForm.company_name"
                  placeholder="请输入工作单位"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="所属单位类型"
                prop="company_type"
                class="company"
              >
                <el-select
                  v-model="ruleForm.company_type"
                  placeholder="请选择所属单位类型"
                >
                  <el-option
                    v-for="(item,index) in danwei"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="职务" prop="job">
                <el-input
                  v-model="ruleForm.job"
                  placeholder="请输入职务"
                ></el-input>
              </el-form-item>

              <el-form-item label="所在地" prop="company_address">
                <div class="diqv_box">
                  <v-distpicker @selected="onSelected_two"  province="四川省" city="成都市" area="锦江区"></v-distpicker>
                </div>
              </el-form-item>
              <el-form-item label="" prop="company_address">
                <el-input
                  v-model="ruleForm.company_address"
                  placeholder="请输入详细地址"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="one">
            <div class="top_box">
              <div class="img_box">
                <img src="../../../assets/img/home/ge_ren.png" />
              </div>
              <div class="biao_ti">个人简历</div>
            </div>
            <div class="form_box">
              <el-form-item
                label="包括个人履历、社会职务、研究成果等"
                class="resume"
              >
                <el-input
                  type="textarea"
                  v-model="ruleForm.resume"
                  placeholder="多行输入"
                ></el-input>
              </el-form-item>

              <el-form-item label="上传证明材料" prop="zfile">
                <el-upload
                  :on-change="fn"
                  class="upload-demo"
                  ref="upload"
                  action=""
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :file-list="fileList"
                  :auto-upload="false"
                  :limit="3"
                >
                  <el-button slot="trigger" size="small" type="primary"
                    >选取文件</el-button
                  >
                  <div slot="tip" class="el-upload__tip">
                    （单个附件最大支持20M，支持格式：PDF、Word、Excel、Txt、JPG、PNG、RAR、ZIP）
                  </div>
                </el-upload>
              </el-form-item>

              <el-form-item label="上传图片" class="header" prop="img">
                <el-upload
                  :on-change="fns"
                  action=""
                  list-type="picture-card"
                  :on-preview="handlePictureCardPreviewG"
                  :on-remove="handleRemoveG"
                  :auto-upload="false"
                  accept="image/*"
                  :class="{ hide: hideUploadG }"
                  class="avatar-uploader"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="submit" @click="ti_jiao('ruleForm')">提交</div>
      </el-form>
    </div>
    <div class="title_flex">
      <div class="line"></div>
      <div class="text">高层次人才对接</div>
      <div class="line"></div>
    </div>
    <div class="three_boxs">
      <div class="gao_box">
        <div class="one">
          <div class="img_box">
            <img src="../../../assets/img/home/gao_one.png" />
          </div>
        </div>
        <div class="two">
          <div class="one_box_two">
            <div class="text_boxes">
              <div class="titles">技术创新服务</div>
              <div class="content">
                成都宋元科技有限公司是一家专业从事电子产品技术开发、技术服务、技术咨询、软件开发及技术服务的企业，通过2年的合作，平台成功策划了基于UWB定位引导的呼吸心率动态测量方法及装置、基于毫米波雷达的人体感知方法及其系统等技术创新服务。
              </div>
            </div>
            <div class="text_boxes aa"></div>
          </div>

          <div class="one_box_two">
            <div class="text_boxes  aa"></div>
            <div class="text_boxes">
              <div class="titles">知识产权保护策划</div>
              <div class="content">
                四川川环科技股份有限公司位于达州市大竹县，高新技术企业、全国创新型企业、全国劳动关系和谐企业、全国重合同守信用企业、全国标准化良好行为AAA企业、全国模范职工之家、全国工人先锋号、四川省科技创新先进单位，中国橡胶工业协会表彰为“中国胶管十强企业”称号。公司于2016年9月30日在深圳证券交易所挂牌上市，股票简称：川环科技，股票代码：300547。2019年起平台与川环科技展开合作，包括成果转移转化、知识产权保护、技术线路规划设计等，截止目前，成功促成芯棒式弯管定型装置、胶管裁切刀具组合装置及胶管裁切设备等知识产权保护策划。
              </div>
            </div>
          </div>

          <div class="one_box_two">
            <div class="text_boxes">
              <div class="titles">校企合作居间转化策划</div>
              <div class="content">
                2019年起，平台为四川天王牧业有限公司企业技术创新能力进行整体评估，并提供创新能力提升方案建议，成功与四川大学达成2020年度四川大学-达州市人民政府专项合作项目：猪粪的资源化综合利用—特色农作物全营养有机无机专用肥开发。
              </div>
            </div>
            <div class="text_boxes aa"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="title_flex">
      <div class="line"></div>
      <div class="text">专家对接现场</div>
      <div class="line"></div>
    </div>
    <div class="four_boxs">
      <div class="zhuan_box">
        <el-tabs  @tab-click="handleClick">
          <el-tab-pane v-for="(item, index) in duijie" :key="index">
            <span slot="label"> {{ item.name }}</span>
            <div class="home">
              <div id="father" class="grid-content bg-purple">
                <div
                  id="swiper-box"
                  class="swiper-box"
                  :style="{ transform: 'translateX(' + currentOffset + 'px)' }"
                >
                  <div v-for="(it, i) in item.photo" :key="i" class="img_flex">
                    <div v-for="(ite, id) in it.photos" :key="id">
                      <cards :ite="ite" :width="width"></cards>
                    </div>
                  </div>
                </div>
                <i class="el-icon-arrow-left" @click="left"></i>
                <i class="el-icon-arrow-right" @click="right"></i>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { handleCompressImg } from "../../../api";
import cards from "../../../components/common/duicards";
import {
  expert_add,
  education,
  danwei,
  lyu,
  photo_list,
} from "../../common/js/api";
export default {
  name: "scientistrz",
  components: {
    cards,
  },
  data() {
    return {
      dialogVisible: false,
      dialogImageUrl: "",
      hideUploadG: false,
      fileList: [],
      lxr: "",
      lxdh: "",
      show: false,
      //省市区
      province: "",
      city: "",
      area: "",
      datass: [],
      value_id: "",
      currentOffset: 0,
      width: "",
      fatherWith: 0,
      box: "",
      labelPosition: "top",
      options: [],
      ruleForm: {
        name: "",
        gender: "",
        native: "",
        province: "",
        city: "",
        area: "",
        birthday: "",
        phone: "",
        education: "",
        school: "",
        research: "",
        is_civil: "",
        job_level: "",
        company_name: "",
        company_type: "",
        job: "",
        company_address: "",
        member_id: JSON.parse(localStorage.getItem("user")).id,
        resume: "",
        zfile: "",
        img: "",
      },
      CommodList: [],
      rules: {
        name: [{ required: true, message: "请输入活动名称", trigger: "blur" }],
        gender: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
        province: [
          { required: true, message: "请选择日期", trigger: "change" },
        ],
        city: [{ required: true, message: "请选择日期", trigger: "change" }],
        area: [{ required: true, message: "请选择日期", trigger: "change" }],
        birthday: [
          { required: true, message: "请选择时间", trigger: "change" },
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "change" },
          {
            pattern: /^1[3|5|7|8|9]\d{9}$/,
            message: "请输入正确的号码格式",
            trigger: "blur",
          },
        ],
        resume: [{ required: false, message: "请填写", trigger: "blur" }],
        education: [{ required: true, message: "请选择", trigger: "change" }],
        school: [{ required: true, message: "请填写", trigger: "blur" }],
        research: [{ required: true, message: "请填写", trigger: "blur" }],
        is_civil: [{ required: true, message: "请填写", trigger: "blur" }],
        job_level: [{ required: true, message: "请填写", trigger: "blur" }],
        company_name: [{ required: true, message: "请填写", trigger: "blur" }],
        company_type: [{ required: true, message: "请填写", trigger: "blur" }],
        job: [{ required: true, message: "请填写", trigger: "blur" }],
        company_address: [
          { required: true, message: "请填写", trigger: "blur" },
        ],

        zfile: [{ required: false, message: "请填写", trigger: "blur" }],
        img: [{ required: true, message: "请上传", trigger: "blur" }],
      },
      xueli: [],
      danwei: [],
      lingyu: [],
      duijie: [],
    };
  },
  mounted() {
    this.setCardWidth();
    this.box = document.querySelector("#swiper-box");
    window.addEventListener("resize", () => {
      this.currentOffset = 0;
      this.setCardWidth();
    });
  },
  created() {
	  if(!localStorage.getItem("user")){
	  		  this.$router.push({
	  		    path: "/login",
	  		  });
	  }
    this.xue_li();
    this.dan_wei();
    this.ling_yu();
    this.dui_jie();
  },
  methods: {
    fns(file, fileList) {
      this.hideUploadG = fileList.length >= 1;
      handleCompressImg(file.raw).then((result) => {
        this.UploadImgs(result, 1);
      });
    },
    //将图片地址上传
    async UploadImgs(file) {
      let formData = new FormData();
      formData.append("file", file);
      await this.$axios
        .post("http://hout.kehui.cloud/api/demand/uploadImg", formData, {
          headers: { "Content-Type": '"multipart/form-data"' },
        })
        .then((res) => {
          console.log(res.data.data);
          this.ruleForm.img = res.data.data;
        });
    },
    handleRemoveG(file, fileList) {
      this.hideUploadG = fileList.length >= 1;
    },
    handlePictureCardPreviewG(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    dui_jie() {
      photo_list({
        username: "",
        password: "",
        name: "",
      })
        .then((res) => {
          this.duijie = res.data;
        })
        .catch((err) => {});
    },
    ti_jiao(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var birthday = "";
          var birthday = this.ruleForm.birthday;
          this.ruleForm.birthday = this.dayjs(birthday).format("YYYY-MM-DD");
          expert_add({
            data: this.ruleForm,
          })
            .then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.$router.push({
                  path: "/humancloud",
                });
              } else {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
              }
            })
            .catch((err) => {});
        } else {
          return false;
        }
      });
    },
    ling_yu() {
      lyu({})
        .then((res) => {
          this.lingyu = res.data;
        })
        .catch((err) => {});
    },
    dan_wei() {
      danwei({})
        .then((res) => {
          this.danwei = res.data;
        })
        .catch((err) => {});
    },
    fn(file, fileList) {
      this.UploadImg(file.raw, fileList);
    },
    //将图片地址上传
    async UploadImg(file) {
      let formData = new FormData();
      formData.append("file", file);
      await this.$axios
        .post("http://hout.kehui.cloud/api/demand/uploadImg", formData, {
          headers: { "Content-Type": '"multipart/form-data"' },
        })
        .then((res) => {
          console.log(res.data.data);
          this.ruleForm.zfile = res.data.data;
        });
    },
    xue_li() {
      education({})
        .then((res) => {
          this.xueli = res.data;
        })
        .catch((err) => {});
    },

    onSelected(data) {
      this.ruleForm.province = data.province.value; //省
      this.ruleForm.city = data.city.value; //市
      this.ruleForm.area = data.area.value; //区
    },

    onSelected_two(data) {
      this.ruleForm.company_address =
        data.province.value +
        data.city.value +
        data.area.value +
        this.ruleForm.company_address;
    },
    handleClick(tab, event) {},

    submitUpload() {
      this.$refs.upload.submit();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    setCardWidth() {
      const father = document.querySelector("#father");
      this.fatherWith = father.clientWidth;
      console.log("fatherWidth", this.fatherWith);
      this.width = (this.fatherWith - 20) / 5 + "px";
    },

    right() {
      console.log(this.currentOffset);
      if (this.currentOffset <= (-(this.fatherWith - 20) / 5) * (10 - 5))
        return;
      this.currentOffset -= (this.fatherWith - 20) / 5;
    },
    left() {
      console.log(this.currentOffset);
      if (this.currentOffset >= -2) return;
      this.currentOffset += (this.fatherWith - 20) / 5;
    },
  },
};
</script>

<style scoped lang="less">
// .header {
//   margin-left: -60px;
// }
/deep/.hide .el-upload--picture-card {
  display: none;
}
.img_flex {
  display: flex;
  flex-direction: row;
}
/deep/.distpicker-address-wrapper select {
  width: 130px;
  padding: 0px 10px!important;
  font-size: 14px;
}
.bg-purple {
  overflow: hidden;
  position: relative;
}
.home {
  .swiper-box {
    display: flex;
    width: auto;
    transition: all 0.2s;
  }
}

[class^="el-icon-arrow-"] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 30px;
  padding: 10px 0;
  background-color: #999;
  cursor: pointer;
}
.el-icon-arrow-left {
  left: -7px;
}
.el-icon-arrow-right {
  right: -7px;
}
/deep/.el-tabs__nav-wrap::after {
  background-color: unset;
}
.container {
  background-color: #f8f9fa;
  width: 100%;
  height: auto;
  overflow: hidden;
  .one_box {
    width: 100%;
    height: 310px;
    line-height: 20px;
    font-size: 14px;
    text-align: center;
    font-family: Roboto;
    /*background-color: #3f7ae7;*/
    background-image: url("~@/assets/img/home/ru_zhu.png");
    background-size: cover;
    border: 1px solid rgba(255, 255, 255, 100);
    .title {
      margin: 30px;
      color: rgba(255, 255, 255, 100);
      font-size: 20px;
    }
    .two_box {
      display: flex;
      width: 800px;
      margin: 0 auto;
      justify-content: space-between;
      .one {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 210px;
        height: 184px;
        color: rgba(255, 255, 255, 100);
        font-size: 14px;
        /*background-color: #fff;*/
        text-align: center;
        .img_box {
          width: 55px;
          height: 57px;
        }
        img {
          width: 55px;
          height: 57px;
        }
        .title_text {
          color: rgba(247, 247, 247, 100);
          font-size: 20px;
          text-align: center;
        }
        .content {
          /*text-overflow: -o-ellipsis-lastline;*/
          /*overflow: hidden;*/
          /*text-overflow: ellipsis;*/
          /*display: -webkit-box;*/
          /*-webkit-line-clamp: 5;*/
          /*line-clamp: 5;*/
          /*-webkit-box-orient: vertical;*/
          margin-top: 10px;
          line-height: 25px;
          color: rgba(255, 255, 255, 100);
          font-size: 14px;
        }
      }
    }
  }
  .two_boxs {
    margin: 30px auto;
    background-color: #fff;
    width: 1200px;
    // height: 1050px;
    line-height: 20px;
    border-radius: 0px 5px 5px 0px;
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    font-family: Roboto;
    border: 1px solid rgba(255, 255, 255, 100);
    .submit {
      cursor: pointer;
      margin: 30px auto;
      width: 106px;
      height: 30px;
      line-height: 30px;
      border-radius: 4px;
      background-color: rgba(255, 255, 255, 100);
      color: rgba(56, 146, 235, 100);
      font-size: 14px;
      text-align: center;
      font-family: Microsoft Yahei;
      border: 1px solid rgba(56, 146, 235, 100);
    }
    .four_flex {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 30px;
    }
    .one {
      width: 480px;
      // height: 300px;

      display: flex;
      flex-direction: column;
      align-items: left;
      margin: 0 30px;
      // 第一个元素
      // &:first-child {
      //   margin: 30px 30px 0;
      // }
      // &:nth-child(2) {
      //   margin: 30px 30px 0;
      // }
      // // 第三个元素
      // &:nth-child(3) {
      //   margin: 0px 60px 30px 60px;
      // }
      // &:nth-child(4) {
      //   margin: 0px 30px 30px 60px;
      // }
      /*background-color: red;*/
      .top_box {
        display: flex;
        align-items: center;
        padding: 10px 10px;
        border-bottom: 2px solid #3892eb;
        width: 125px;
        .img_box {
          width: 24px;
          height: 24px;
          margin-right: 5px;
          img {
            width: 24px;
            height: 24px;
          }
        }
        .biao_ti {
          color: rgba(56, 146, 235, 100);
          font-size: 18px;
        }
      }
      .form_box {
        margin-top: 10px;
        margin-left: -20px;
      }
      &:nth-child(4) .form_box {
        margin-left: 0;
      }
    }
  }
  .title_flex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .text {
      font-size: 28px;
      color: #3892eb;
      font-weight: 600;
    }
    .line {
      margin: 100px;
      width: 60px;
      height: 1px;
      background-color: #3892eb;
    }
  }
  .three_boxs {
    width: 1200px;
    margin: 0 auto;
    /*background-color: red;*/
    .gao_box {
      position: relative;
      .one {
        width: 682px;
        height: 457px;
        background-color: #fff;
        line-height: 20px;
        border-radius: 0px 5px 5px 0px;
        color: rgba(16, 16, 16, 100);
        font-size: 14px;
        text-align: center;
        font-family: Roboto;
        border: 1px solid rgba(255, 255, 255, 100);
        .img_box {
          margin: 40px auto;
          width: 577px;
          height: 375px;
        }
        img {
          width: 577px;
          height: 375px;
        }
      }
      .two {
        position: absolute;
        left: 610px;
        top: 60px;
        width: 587px;
        height: 342px;
        line-height: 20px;
        border-radius: 0px 5px 5px 0px;
        background-color: rgba(56, 146, 235, 100);
        color: rgba(16, 16, 16, 100);
        font-size: 14px;
        text-align: center;
        font-family: Roboto;
        border: 1px solid rgba(255, 255, 255, 100);
        z-index: 99;
        .one_box_two {
          display: flex;
          .text_boxes {
            margin: 15px 40px 15px 40px;

            text-align: left;
            width: 247px;
            .titles {
              width: 300px;
              color: #fff;
              font-size: 18px;
            }
            .content {
              //两行
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
              margin-top: 5px;
              line-height: 30px;
              color: #fff;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .four_boxs {
    background-color: #fff;
    width: 100%;
    height: auto;
    line-height: 20px;
    border: 1px solid rgba(255, 255, 255, 100);
    .zhuan_box {
      width: 1200px;
      margin: 20px auto;
    }
  }
}
.civil /deep/.el-form-item__label {
  width: 200px !important;
}
.company /deep/.el-form-item__label {
  width: 110px !important;
}
.resume /deep/ .el-form-item__label {
  width: 265px !important;
}
.resume /deep/.el-form-item__content {
  margin-left: 0 !important;
}
/deep/.el-textarea__inner {
  resize: none;
}
@media screen and (max-width: 1200px) {
	.container .title_flex .text{
		font-size: 2.5rem;
	}
	.container .one_box .title{
		font-size: 1.8rem;
	}
	.container .one_box .two_box{
		width: 90%;
	}
	.container .one_box .two_box .one{
		height: auto;
		width: 31%;
	}
	.container .one_box .two_box .one .title_text{
		font-size: 1.6rem;
	}
	.container .one_box .two_box .one .content{
		font-size: 1.4rem;
		line-height: 2.2rem;
	}
	.container .one_box{
		height: 28rem;
		line-height: 2.5rem;
		
	}
	.container .two_boxs{
		width: 98%;
	}
	.container .two_boxs .one{
		width: 100%;
		margin: 0;
	}
	.container .two_boxs .one .top_box .biao_ti{
		font-size: 1.7rem;
	}
	.container .two_boxs .one .top_box{
		width: 11rem;
	}
	.container .two_boxs .one .top_box .img_box{
		width: 2rem;
		height: 2rem;
	}
	.container .two_boxs .one .top_box .img_box img{
		width: 2rem;
		height: 2rem;
	}
	/deep/.el-form-item{
		margin: 2rem 0;
	}
	/deep/.el-form-item__label{
		width: 10rem !important;
		font-size: 1.5rem;
		line-height: 4rem;
	}
	/deep/.el-input__inner{
		height: 4rem;
		line-height: 4rem;
		font-size: 1.5rem;
	}
	/deep/.el-form-item__content{
		margin-left: 10.5rem !important;
	}
	/deep/.el-radio__input{
		font-size: 1.5rem !important;
	}
	/deep/.el-radio__label{
		font-size: 1.5rem !important;
	}
	/deep/.distpicker-address-wrapper select{
		width: 10rem;
		height: 4rem;
		line-height: 4rem;
		font-size: 1.5rem;
	}
	.container .three_boxs .gao_box .one{
		display: none;
	}
	.container .three_boxs .gao_box .two{
		position: relative;
		left: 0;
		top: 0;
		width: 100%;
		height: auto;
	}
	.container .three_boxs .gao_box .two .one_box_two .text_boxes{
		width: 75%;
	}
	.container .three_boxs .gao_box .two .one_box_two .text_boxes .titles{
		width: 100%;
		font-size: 2rem;
		padding: 1rem 0;
	}
	.container .two_boxs .submit{
		width: 8rem;
		height: 3rem;
		line-height: 3rem;
		font-size: 1.5rem;
	}
	/deep/.el-upload__tip{
		font-size: 1.4rem;
	}
	/deep/.el-button--small{
		font-size: 1.8rem;
	}
	.container .three_boxs .gao_box .two .one_box_two .text_boxes .content{
		font-size: 1.4rem;
		line-height: 2.2rem;
	}
	.one_box_two .one_box_two .text_boxes:first-child{
		display: none;
	}
	.aa{
		display: none;
	}
	/deep/.el-tabs__item{
		font-size: 1.5rem;
		height: 3rem;
	}
}
</style>