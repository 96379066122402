<template>
    <div class="bg_box">
        <div class="img_boxsss"  >
            <div class="nei_box">
                <img :src="ite|ImgFilter"/>
            </div>
        </div>
    </div>
</template>

<script>
    import {photo_list} from '../../views/common/js/api'
    export default {
        props:['ite'],
        data () {
            return {
                currentDate: new Date(),
                arr:[]
            }
        },
        filters:{
            ImgFilter(value){
                return 'http://hout.kehui.cloud' + value
            }
        },
        methods:{
            dui_jie(){
                photo_list({
                    username:"",
                    password:"",
                    name:"",
                }).then((res) => {
                    console.log("111111")
                    console.log(res)
                    this.duijie=res.data
                    console.log(this.duijie)
                }).catch((err) => {
                });
            },
        },
        created(){
            // console.log("7777")
            // this.dui_jie()
        }
    }
</script>

<style lang='less' scoped>
    .nei_box{
        color: #fff;
        /*margin: 20px 10px;*/
        .title{
            font-weight: 600;
            color: rgba(255, 255, 255, 100);
            font-size: 20px;
        }
        .content{
            color: rgba(255, 255, 255, 100);
            font-size: 12px;
        }
    }
    .bg_box{
        display: flex;
    }
    .img_boxsss{
        margin-left:55px;
        width: 330px;
        height: 190px;
        background-image: url("~@/assets/img/home/bg_card_two.png") ;
        background-size:100% 100%;
        img{
            width: 330px;
            height: auto;
        }
    }


</style>
