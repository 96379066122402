import { render, staticRenderFns } from "./scientistrz.vue?vue&type=template&id=4e305655&scoped=true&"
import script from "./scientistrz.vue?vue&type=script&lang=js&"
export * from "./scientistrz.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e305655",
  null
  
)

export default component.exports