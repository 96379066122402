<template>
    <div>
        <con-tab ></con-tab>
        <home-swiper :imgList="imgList" class="swiper_class"></home-swiper>
        <!--轮播图-->
        <need-nei ></need-nei>
    </div>
</template>

<script>
    import ConTab from "../../components/common/ConTab";
    import NeedNei from "./base/scientistrz";
    import HomeSwiper from "../../components/common/Swiper";
    import {lun_ren} from "../common/js/api";
    export default {
        components:{
            ConTab,
            NeedNei,
            HomeSwiper
        },
        data(){
            return{
                imgList:[]
            }
        },
        created(){
            this.lun_bo()
        },
        methods:{
            //机构云轮播
            lun_bo(){
                lun_ren({}).then((res) => {
                    this.imgList=res.data
                }).catch((err) => {
                });
            },
        }
    }
</script>

<style scoped>

</style>